export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313547/about_bmb4jj.jpg'
export const contactbg =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313958/contactbg_mpwpa0.jpg'
export const hero =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313255/mainbg_zcvmhr.png'
export const heroText =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1614715998/yalesushi/heroText.png'
export const logo =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614196769/logo_ovjmeo.png'
export const locationMap =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313791/locationMap_wq8xnw.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusionlisa/image/upload/f_auto,q_auto:best/v1615244107/yalesushi/aboutMobile.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614260318/contactbgMobile_jvbjl5.png'
export const mobileHero =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614259189/mainbgMobile_nvokef.png'
export const mobileMap =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614260213/locationMapMobile_g5ix6v.png'
export const mobileHeroText =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614259343/heroTextMobile_qs48oa.png'
export const mobilePromotion =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614259684/promotionsMobile_smlc95.png'
export const orderPickupButton =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614259594/orderPickupButtonMobile_wfzett.png'
export const promotions =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313458/promotions_uyuq8g.jpg'
export const foodGallery =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614313625/foodGallery_qlrqli.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1614259960/foodgalleryMobile_d2nr1i.png'
