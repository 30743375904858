import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#FFB800" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center white bold>
            <CFLink href="https://www.facebook.com/YaleSushi">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Yale sushi Facebook"
              />
            </CFLink>
            {/* <CFLink href="https://www.yelp.ca/biz/88-palpal-noodle-house-burnaby">
              <CFImage w="40px" pr="10px" src={yelp} alt="Yale sushi Yelp" />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Yale sushi Zomato"
              />
            </CFLink> */}
            {/* <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Yale sushi Instagram"
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#FFB800" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter white bold>
            <CFLink href="https://www.facebook.com/YaleSushi">
              <CFImage
                w="40px"
                pr="10px"
                src={facebook}
                alt="Yale sushi Facebook"
                hover
              />
            </CFLink>
            {/* <CFLink href="https://www.yelp.ca/biz/88-palpal-noodle-house-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={yelp}
                alt="Yale sushi Yelp"
                hover
              />
            </CFLink>
            <CFLink href="https://www.zomato.com/vancouver/88-palpal-noodle-house-central-burnaby-burnaby">
              <CFImage
                w="40px"
                pr="10px"
                src={zomato}
                alt="Yale sushi Zomato"
                hover
              />
            </CFLink> */}
            {/* <CFLink href="https://www.instagram.com/88noodles/">
              <CFImage
                w="40px"
                pr="10px"
                src={instagram}
                alt="Yale sushi Instagram"
                hover
              />
            </CFLink> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
